<template>
	<div class="position-relative">
    <div ref='player' id="player">
      <loader/>
    </div>
    <div v-if="error" class="error-message" @click.stop.prevent="onErrorClick">{{$t('player-error')}}</div>
  </div>
</template>


<script>
  import Loader from "../../Loader";
  import {PLAYER_ERROR} from "@/const";

  export default {
    name: "youtubeEmbeddedPlayer",
    components: {Loader},
    props: {
      link: {
        type: String,
      },
      autoplay:{
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        done: false,
        width: this.getWidth(),
        height: this.getHeight(),
        error: false,
        currentRefreshIndex: 0,
        refreshInterval: null,
      }
    },
    PLAYER: null,
    ASPECT_RATIO: (16 / 9),
    methods: {
      loadScript() {
        var tag = document.createElement('script');

        tag.src = "https://www.youtube.com/iframe_api";
        var firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
      },
      initPlayer() {
        this.$options.PLAYER = new window.YT.Player(this.$refs.player, {
          videoId: this.videoId,
          width: this.width,
          height: this.height,
          playerVars: {
            autoplay: Number(this.autoplay),
            modestbranding: 1,
            origin: window.location.origin,
            rel: 0,
          },
          events: {
            'onReady': this.onReady,
            'onError': this.onError,
            'onStateChange': this.onStateChange,
          }
        });
      },
      play() {
        this.$options.PLAYER?.playVideo?.()
      },
      onReady(){
        this.autoplay && this.play()
      },
      destroy() {
        this.$options.PLAYER?.destroy?.()
      },
      getWidth() {
        const upperWidth = Math.min(window.innerWidth * .75, 1600),
            lowestWidth = Math.min(600, window.innerWidth * .75)
        return this.width = parseInt(String(Math.max(upperWidth, lowestWidth)));
      },
      getHeight() {
        const aspected = this.getWidth() / 16 * 9;
        if (window.innerHeight > aspected) {
          return this.height = aspected
        } else {
          const lowestHeight = Math.min(210, window.innerHeight * .6)
          this.height = Math.max(window.innerHeight * .6, lowestHeight)
        }
      },
      onResize() {
        const getSizes = () => {
          this.getWidth();
          this.getHeight();
        }
        window.addEventListener('resize', getSizes)
        this.$once('hook:beforeDestroy', () => {
          window.removeEventListener('resize', getSizes)
        })
      },
      setSize() {
        this.$options.PLAYER?.setSize(this.width, this.height)
      },
      autoReloadPlayer(){
        clearInterval(this.refreshInterval);
        this.destroy();
        this.error = false;
        this.$nextTick(this.initPlayer)
      },

      onError(event) {
        console.error('Youtube embedded player error', event)
        this.error = true;
        if (this.currentRefreshIndex > PLAYER_ERROR.MAX_REFRESH_COUNT) return;
        const interval = this.currentRefreshIndex * PLAYER_ERROR.REFRESH_DURATION;
        this.currentRefreshIndex += 1;
        this.refreshInterval = setTimeout(this.autoReloadPlayer, interval)
      },
      onErrorClick(){
        this.autoReloadPlayer()
      }
    },
    watch: {
      width: 'setSize',
      height: 'setSize',
    },
    computed: {
      videoId() {
        const url = new URL(this.link, location.origin);
        //for links like https://www.youtube.com/watch?v={id}
        if (url.pathname.includes('watch') && url.searchParams.get('v')){
          return  url.searchParams.get('v')
        }
        //for links like https://youtu.be/{id} && https://www.youtube.com/embed/{id}
        const path = url.pathname.replace(/\/$/, '');
        return path.slice(path.lastIndexOf('/') + 1)
      },
    },
    mounted() {
      if (window.YT?.Player) {
        return this.initPlayer();
      }
      window.onYouTubeIframeAPIReady = () => this.initPlayer()
      this.onResize()
    },
    created() {
      this.loadScript();
    },
    beforeDestroy() {
      this.destroy()
      clearInterval(this.refreshInterval);
    }
  }
</script>
<style>
.error-message{
  position: absolute;
  z-index: 1;
  background: #1f1f1f;
  color: #fff;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>